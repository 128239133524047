import React from "react"
import SwiperCore, {
  Autoplay,
  EffectFade,
  HashNavigation,
  Navigation,
  Pagination,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import Image from "./DynamicImage"

SwiperCore.use([Autoplay, Navigation, Pagination, HashNavigation, EffectFade])

export default function Carousel({ data, auto = false, cssClass }) {
  // const { data, cssClass } = props
  // console.log(data)

  if (auto === true) {
    // SwiperCore.use([Autoplay])
  }

  return (
    <Swiper
      loop="true"
      dir="rtl"
      autoplay={{ delay: Math.random() * (5000 - 3000) + 3000 }}
      effect="fade"
      speed={2000}
      fadeEffect={{ crossFade: true }}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      // navigation={{
      //   nextEl: `{.${cssClass}-next}`,
      //   prevEl: `{.${cssClass}-prev}`,
      // }}
      // onSwiper={swiper => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
    >
      {data &&
        data.map((slide, index = 1) => {
          // let content = slide.text
          // console.log(slide.image.databaseId)
          return (
            <SwiperSlide
              key={slide?.image?.databaseId}
              data-hash={`carousel-${index}`}
              id={`carousel-${index}`}
              className={cssClass}
            >
              <section className="flex middle">
                {slide?.image && (
                  <Image data={slide?.image} classes="bgimage" />
                )}
              </section>
            </SwiperSlide>
          )
        })}
    </Swiper>
  )
}
