import React from "react"
import Map from "./map"
import places from "./places.json"

function GoogleMap() {
  var isMobile = false
  if (typeof window !== `undefined`) {
    isMobile = window.matchMedia("(max-width: 768px)").matches
  }
  return (
    <>
      <Map
        center={{ lat: 24.800886, lng: 46.651313 }}
        zoom={isMobile ? 15 : 16}
        places={places}
      />
    </>
  )
}

export default GoogleMap
